import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        width: "36px",
        height: "36px",
        borderRadius: "50%",
        backgroundColor: "black",
        border: "2px solid rgba(255, 255, 255, 0.605)",
      }}
      onClick={onClick}
    >
      <i className="fas fa-angle-right" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    // <a href="#" className={`slidPrv_1 ${className}`} onClick={onClick}>
    //   <i className="fas fa-angle-left" />
    // </a>
    <div
      className={className}
      style={{
        ...style,
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        width: "36px",
        height: "36px",
        borderRadius: "50%",
        border: "2px solid rgba(255, 255, 255, 0.605)",
      }}
      onClick={onClick}
    >
      <i className="fas fa-angle-left" />
    </div>
  );
}
const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 2,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};
const Results = () => {
  return (
    <section className="py-100 position-relative">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="common-heading">
              <h2>Our Results Speak</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
                Praesent mollis erat sed venenatis tempus.
              </p>
            </div>
          </div>
        </div>
        <Slider {...settings}>
          <div className="col-lg-5">
            <div className="speak-box">
              <h5>Before InstaRISE</h5>
              <img
                src="./assets/images/before.png"
                alt="a"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="speak-box after-bg border-0">
              <h5 className="d-flex align-items-center justify-content-center">
                <img
                  src="./assets/images/star-w.svg"
                  alt="a"
                  className="img-fluid me-2"
                />{" "}
                After InstaRISE
              </h5>
              <img
                src="./assets/images/after-img.png"
                alt="a"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="speak-box">
              <h5>Before InstaRISE</h5>
              <img
                src="./assets/images/before.png"
                alt="a"
                className="img-fluid"
              />
            </div>
          </div>
        </Slider>

        <div className="row justify-content-center speak-slider"></div>
      </div>
    </section>
  );
};

export default Results;
