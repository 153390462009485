import React from "react";

const FaqLanding = () => {
  return (
    <section className="py-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="common-heading">
              <h2>Frequently Asked Questions</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
                Praesent mollis erat sed venenatis tempus.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="faq-ask-wrap">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      <span>Q</span> Is there a risk of shadowbanning?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p>
                        We have been developing an algorithm since 2017 and
                        offering it to our clients since 2019. Despite these
                        years, no client has ever been banned because of
                        automation. This is explained by our security and
                        account protection processes.
                      </p>
                      <p>
                        We have developed a method that allows us to simulate
                        human behavior, with a French connection from an iPhone
                        and thus not to be detected by Instagram. Our algorithm
                        aims to destroy Shadowban, not cause it. By using our
                        services, your engagement rate will increase, which will
                        allow you to gain visibility.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      <span>Q</span>How many subscribers can I gain?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p>
                        We have been developing an algorithm since 2017 and
                        offering it to our clients since 2019. Despite these
                        years, no client has ever been banned because of
                        automation. This is explained by our security and
                        account protection processes.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      <span>Q</span>Are these qualified subscribers?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p>
                        We have been developing an algorithm since 2017 and
                        offering it to our clients since 2019. Despite these
                        years, no client has ever been banned because of
                        automation. This is explained by our security and
                        account protection processes.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      <span>Q</span>Can I cancel my subscription?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p>
                        We have been developing an algorithm since 2017 and
                        offering it to our clients since 2019. Despite these
                        years, no client has ever been banned because of
                        automation. This is explained by our security and
                        account protection processes.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                    >
                      <span>Q</span>Can I use InstaRISE on multiple accounts?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFive"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p>
                        We have been developing an algorithm since 2017 and
                        offering it to our clients since 2019. Despite these
                        years, no client has ever been banned because of
                        automation. This is explained by our security and
                        account protection processes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="have-more">
              <p>Have more questions ?</p>
              <a href="#" style={{ textDecoration: "none" }}>
                Contact Us <i className="fas fa-arrow-right" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqLanding;
