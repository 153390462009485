import React from "react";
import "../css/style.css";
import "../css/responsive.css";
import Header from "../components/Header";
const Terms = () => {
  return (
    <>
      {/* header section start */}
      <Header />
      {/* header section end */}
      {/* terms text */}
      <section className="py-100">
        <div className="container">
          {/* common heading start */}
          <div className="row">
            <div className="col-lg-12">
              <div className="common-heading">
                <h2 className="text-white">Terms of Service</h2>
                <p>Last Revised : Mar 06, 2024</p>
              </div>
            </div>
          </div>
          {/* common heading end */}
          <div className="row justify-content-center">
            <div className="col-lg-9 col-md-10">
              <div className="terms-txt">
                <p>
                  Welcome to www.lorem-ipsum.info. This site is provided as a
                  service to our visitors and may be used for informational
                  purposes only. Because the Terms and Conditions contain legal
                  obligations, please read them carefully.
                </p>
                <p className="mt-15">1. YOUR AGREEMENT </p>
                <p>
                  By using this Site, you agree to be bound by, and to comply
                  with, these Terms and Conditions. If you do not agree to these
                  Terms and Conditions, please do not use this site.
                </p>
                <p>
                  PLEASE NOTE: We reserve the right, at our sole discretion, to
                  change, modify or otherwise alter these Terms and Conditions
                  at any time. Unless otherwise indicated, amendments will
                  become effective immediately. Please review these Terms and
                  Conditions periodically. Your continued use of the Site
                  following the posting of changes and/or modifications will
                  constitute your acceptance of the revised Terms and Conditions
                  and the reasonableness of these standards for notice of
                  changes. For your information, this page was last updated as
                  of the date at the top of these terms and conditions.
                </p>
                <p className="mt-15">2. PRIVACY POLICY</p>
                <p>
                  Please review our Privacy Policy, which also governs your
                  visit to this Site, to understand our practices
                </p>
                <p className="mt-15">3. LINKED SITES</p>
                <p>
                  This Site may contain links to other independent third-party
                  Web sites ("Linked Sites”). These Linked Sites are provided
                  solely as a convenience to our visitors. Such Linked Sites are
                  not under our control, and we are not responsible for and does
                  not endorse the content of such Linked Sites, including any
                  information or materials contained on such Linked Sites. You
                  will need to make your own independent judgment regarding your
                  interaction with these Linked Sites.
                </p>
                <p className="mt-15">4. FORWARD LOOKING STATEMENTS</p>
                <p>
                  All materials reproduced on this site speak as of the original
                  date of publication or filing. The fact that a document is
                  available on this site does not mean that the information
                  contained in such document has not been modified or superseded
                  by events or by a subsequent document or filing. We have no
                  duty or policy to update any information or statements
                  contained on this site and, therefore, such information or
                  statements should not be relied upon as being current as of
                  the date you access this site.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* terms text */}
      {/* footer section start */}
      <footer className="footer_section ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="ftr-copy">
                <p className="para">
                  © 2024 InstaRISE.&nbsp;All rights reserved
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ftr-menus">
                <ul className="justify-content-start mt-3">
                  <li>
                    <a href="/terms" style={{ textDecoration: "none" }}>
                      Terms
                    </a>
                  </li>
                  <li>
                    <a href="#" style={{ textDecoration: "none" }}>
                      Privacy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* footer section end */}
    </>
  );
};

export default Terms;
