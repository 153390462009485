import React from "react";
import Header from "./Header";
const Hero = () => {
  return (
    <section className="hero-section">
      {/* header section start */}
      <Header />
      {/* header section end */}
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="hero-txt-wrap">
              <img
                src="./assets/images/hero-mobile.svg"
                alt="hero-mobile"
                className="img-fluid d-lg-none mb-4"
              />
              <h6>
                <span>OFFER</span> Get 10% Discount | Use Code : NEW10
              </h6>
              <h1>
                Get Verified{" "}
                <img
                  src="./assets/images/badge.svg"
                  alt="a"
                  className="img-fluid"
                />{" "}
                Instagram <br />
                Followers Instantly
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
                Praesent mollis erat sed venenatis tempus.
              </p>
              <div className="hero_btn">
                <a href="#" style={{ textDecoration: "none" }}>
                  Get Started <i className="fas fa-arrow-right" />
                </a>
              </div>
              <div className="d-flex feat">
                <p>
                  <i className="fas fa-check" /> Super Fast Results
                </p>
                <p>
                  <i className="fas fa-check" /> Instant Activation
                </p>
                <p>
                  <i className="fas fa-check" /> Easy to Access
                </p>
              </div>
            </div>
            <div className="hero-user-list">
              <h6>InstaRise is Trusted by 2500+ Users</h6>
              <ul>
                <li>
                  <a href="#">
                    <img
                      src="./assets/images/icons/avatar-01.png"
                      alt="avatar"
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="./assets/images/icons/avatar-02.png"
                      alt="avatar"
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="./assets/images/icons/avatar-03.png"
                      alt="avatar"
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="./assets/images/icons/avatar-04.png"
                      alt="avatar"
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="./assets/images/icons/avatar-05.png"
                      alt="avatar"
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="./assets/images/icons/avatar-06.png"
                      alt="avatar"
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="./assets/images/icons/avatar-07.png"
                      alt="avatar"
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="./assets/images/icons/avatar-08.png"
                      alt="avatar"
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="./assets/images/icons/avatar-01.png"
                      alt="avatar"
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="./assets/images/icons/avatar-02.png"
                      alt="avatar"
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="./assets/images/icons/avatar-03.png"
                      alt="avatar"
                      className="img-fluid"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
