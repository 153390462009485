import React, { useEffect, useState } from "react";
import "../css/style.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import StarterPlan, { PlusPlan, PremiumPlan } from "./PricingCard";

const PricingSec = () => {
  const navigate = useNavigate();

  const [Plans, setPlans] = useState([]);
  const handleGetStarted = (plan) => {
    // Navigate to checkout page and pass selected plan as state
    navigate("/checkout", {
      state: {
        selectedPlan: plan._id,
      },
    });
  };

  const getPlans = async () => {
    await axios
      .get(`https://api.instarise.xyz/api/v1/admin/plan-pricing`)
      .then((res) => {
        setPlans(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getPlans();
  }, []);
  return (
    <section className="py-100 ">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="common-heading">
              <h2>Clear Simple Pricing</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
                Praesent mollis erat sed venenatis tempus.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {/* <div className="pricing-head-tab position-relative mb-90">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    1 Account
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    2 Accounts
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    3 Accounts
                  </button>
                </li>
              </ul>
              <div className="discount-tag position-absolute">
                <img
                  src="./assets/images/icons/discount-tag.svg"
                  alt="discount-tag"
                  className="img-fluid"
                />
              </div>
            </div> */}
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabIndex={0}
              >
                <div className="row">
                  <StarterPlan
                    data={Plans?.find((item) => item.planType === "starter")}
                    handleGetStarted={handleGetStarted}
                  />
                  <PlusPlan
                    data={Plans?.find((item) => item.planType === "plus")}
                    handleGetStarted={handleGetStarted}
                  />
                  <PremiumPlan
                    data={Plans?.find((item) => item.planType === "premium")}
                    handleGetStarted={handleGetStarted}
                  />
                </div>
              </div>
              {/* <div
                className="tab-pane fade show"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
                tabIndex={0}
              >
                <div className="row">
                  <StarterPlan
                    data={Plans?.find(
                      (item) =>
                        item.accountType === "twoaccount" &&
                        item.planType === "starter"
                    )}
                    handleGetStarted={handleGetStarted}
                  />
                  <PlusPlan
                    data={Plans?.find(
                      (item) =>
                        item.accountType === "twoaccount" &&
                        item.planType === "plus"
                    )}
                    handleGetStarted={handleGetStarted}
                  />
                  <PremiumPlan
                    data={Plans?.find(
                      (item) =>
                        item.accountType === "twoaccount" &&
                        item.planType === "premium"
                    )}
                    handleGetStarted={handleGetStarted}
                  />
                </div>
              </div>
              <div
                className="tab-pane fade show "
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
                tabIndex={0}
              >
                <div className="row">
                  <StarterPlan
                    data={Plans?.find(
                      (item) =>
                        item.accountType === "fiveaccount" &&
                        item.planType === "starter"
                    )}
                    handleGetStarted={handleGetStarted}
                  />
                  <PlusPlan
                    data={Plans?.find(
                      (item) =>
                        item.accountType === "fiveaccount" &&
                        item.planType === "plus"
                    )}
                    handleGetStarted={handleGetStarted}
                  />
                  <PremiumPlan
                    data={Plans?.find(
                      (item) =>
                        item.accountType === "fiveaccount" &&
                        item.planType === "premium"
                    )}
                    handleGetStarted={handleGetStarted}
                  />
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-12">
            <div className="have-more">
              <p>Need any custom plans? </p>
              <a href="#" style={{ textDecoration: "none" }}>
                Contact Us <i className="fas fa-arrow-right" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingSec;
