import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Copy } from "lucide-react";
import { toast } from "react-toastify";
import { Badge, Form, FormControl, InputGroup, Spinner } from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";
const planSelected = [
  { planType: "starter", price: 89, accountType: "oneaccount" },
  { planType: "plus", price: 89, accountType: "oneaccount" },
  { planType: "premium", price: 89, accountType: "oneaccount" },
];
const OrderInfo = () => {
  const { id } = useParams();
  console.log(id);
  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);

    toast.success("Copied to clipboard!");
  };
  useEffect(() => {
    getOrderInfo();
  }, []);
  const [orderData, setOrderData] = useState({});
  const [invoiceData, setInvoiceData] = useState({});

  const getOrderInfo = async () => {
    await axios
      .get(`https://api.instarise.xyz/api/v1/order/read/${id}`)
      .then((res) => {
        setOrderData(res.data.order);
        setInvoiceData(res.data.invoice);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {/* header section start */}
      <Header />
      {/* header section end */}
      <section className="py-100">
        {!orderData ? (
          <Spinner animation="border" />
        ) : (
          <div className="container">
            {" "}
            <div className=" active row ">
              <div className="col-lg-4">
                <div className="pricing-box-wrap ">
                  <div className="">
                    <h4 className="text-center text-secondary">
                      Order Details
                    </h4>
                  </div>
                  <div className="mt-4">
                    <h6>
                      <span>OrderId : </span> {orderData._id}
                    </h6>
                    <h6>
                      <span>Order Status : </span>
                      <Badge
                        className="text-capitalize"
                        bg={
                          orderData.status === "confirmed"
                            ? "success"
                            : orderData.status === "cancelled"
                            ? "danger"
                            : "warning"
                        }
                      >
                        {orderData.status}
                      </Badge>
                    </h6>
                    <h6>
                      <span>Name : </span> {orderData?.user?.name}
                    </h6>
                    <h6>
                      <span>Email : </span> {orderData?.user?.email}
                    </h6>
                    <h6>
                      <span>Phone : </span> {orderData?.user?.phone ?? "N/A"}
                    </h6>

                    <Form.Group className="mb-3 d-flex justify-content-center text-center gap-2 align-middle ">
                      <h6
                        className="text-center mt-1"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Key :{" "}
                      </h6>

                      <InputGroup className="mb-3">
                        <FormControl
                          size="md"
                          className="text-black"
                          type="text"
                          name="apiKey"
                          value={
                            orderData.key
                              ? orderData.key
                              : "Keys not assigned yet"
                          }
                        />

                        <InputGroup.Text
                          className="text-success cursor-pointer"
                          onClick={() =>
                            copyToClipboard(
                              orderData.key
                                ? orderData.key
                                : "Keys not assigned yet"
                            )
                          }
                        >
                          <Copy />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 ">
                <div className="pricing-box-wrap">
                  <div className="flex">
                    <div>
                      <h4 className="text-center text-secondary">
                        Payment Details
                      </h4>
                    </div>
                    <div className="mt-4">
                      <h6>
                        <span>InvoiceId : </span> {invoiceData._id}
                      </h6>
                      <h6>
                        <span>Invoice Status : </span>
                        <Badge
                          className="text-capitalize"
                          bg={
                            invoiceData.status === "success"
                              ? "success"
                              : invoiceData.status === "cancelled"
                              ? "danger"
                              : "warning"
                          }
                        >
                          {invoiceData.status}
                        </Badge>
                      </h6>
                      <h6 className="text-primary">
                        <span className="text-white">Payment Method : </span>{" "}
                        {invoiceData.payment_method}
                      </h6>
                      <h6>
                        <span>Amount : </span>
                        <Badge bg="secondary"> $ {invoiceData.amount}</Badge>
                      </h6>
                      <h6 className="font-weight-bold">
                        <span>Date : </span>{" "}
                        {new Date(invoiceData.createdAt).toLocaleDateString()}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 ">
                <div className="pricing-box-wrap active">
                  <div className="flex">
                    <div>
                      <h4 className="text-center text-secondary">
                        Selected Plan
                      </h4>
                      <h2 className="text-success text-capitalize">
                        {orderData?.plan?.planType}
                      </h2>
                      <h6>Our Best Seller</h6>
                    </div>
                  </div>
                  <div className="d-flex">
                    <h4>${orderData?.plan?.price ?? 0}</h4>
                    <span>
                      per user/
                      {orderData?.plan?.planType === "starter"
                        ? "week"
                        : orderData?.plan?.planType === "plus"
                        ? "month"
                        : "year"}
                      <br />
                      billed{" "}
                      {orderData?.plan?.planType === "starter"
                        ? "weekly"
                        : orderData?.plan?.planType === "plus"
                        ? "monthly"
                        : "yearly"}
                    </span>
                  </div>

                  {/* <h6>What’s included in Starter :</h6>
                  <div className="media">
                    <i className="fas fa-check" />
                    <div className="media-body">
                      <h5>Everything Automated</h5>
                      <p>Auto Story Likes, Follow/Unfollow</p>
                    </div>
                  </div>
                  <div className="media">
                    <i className="fas fa-check" />
                    <div className="media-body">
                      <h5>Followers per month</h5>
                      <p>500 to 1,000 Followers</p>
                    </div>
                  </div>
                  <div className="media">
                    <i className="fas fa-check" />
                    <div className="media-body">
                      <h5>Exclusives</h5>
                      <p>Auto Story Likes, Follow/Unfollow</p>
                    </div>
                  </div>
                  <div className="media ">
                    <i className="fas fa-check" />
                    <div className="media-body">
                      <h5>Expert Tutorials and Prompts</h5>
                      <p>Full Access to our expert videos</p>
                    </div>
                  </div>
                  <div className="media closed">
                    <i className="fas fa-close" />
                    <div className="media-body">
                      <h5>Dedicated Instagram Expert</h5>
                      <p>Personal Instagram Expert for you</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      <Footer />
    </>
  );
};

export default OrderInfo;
