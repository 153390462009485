import React from "react";

const Review = () => {
  return (
    <div className="py-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="common-heading">
              <h2>Read the Reviews</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
                Praesent mollis erat sed venenatis tempus.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-12 col-md-6">
            <div className="review-box">
              <h5>I absolutely love it</h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                iaculis, sem id auctor maximus, justo neque porttitor ante, at
                varius sapien augue in tortor. Duis tempor convallis sem sed
                bibendum
              </p>
              <div className="media">
                <img
                  src="./assets/images/icons/big-avatar.png"
                  alt="XYZ User"
                  className="img-fluid"
                />
                <div className="media-body">
                  <h6>XYZ User</h6>
                  <span>CEO @XYZ Company</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-12 col-md-6">
            <div className="review-box">
              <h5>I absolutely love it</h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                iaculis, sem id auctor maximus, justo neque porttitor ante, at
                varius sapien augue in tortor. Duis tempor convallis sem sed
                bibendum
              </p>
              <div className="media">
                <img
                  src="./assets/images/icons/big-avatar.png"
                  alt="XYZ User"
                  className="img-fluid"
                />
                <div className="media-body">
                  <h6>XYZ User</h6>
                  <span>CEO @XYZ Company</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-12 col-md-6">
            <div className="review-box">
              <h5>I absolutely love it</h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                iaculis, sem id auctor maximus, justo neque porttitor ante, at
                varius sapien augue in tortor. Duis tempor convallis sem sed
                bibendum
              </p>
              <div className="media">
                <img
                  src="./assets/images/icons/big-avatar.png"
                  alt="XYZ User"
                  className="img-fluid"
                />
                <div className="media-body">
                  <h6>XYZ User</h6>
                  <span>CEO @XYZ Company</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-12 col-md-6">
            <div className="review-box">
              <h5>I absolutely love it</h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                iaculis, sem id auctor maximus, justo neque porttitor ante, at
                varius sapien augue in tortor. Duis tempor convallis sem sed
                bibendum
              </p>
              <div className="media">
                <img
                  src="./assets/images/icons/big-avatar.png"
                  alt="XYZ User"
                  className="img-fluid"
                />
                <div className="media-body">
                  <h6>XYZ User</h6>
                  <span>CEO @XYZ Company</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-12 col-md-6">
            <div className="review-box">
              <h5>I absolutely love it</h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                iaculis, sem id auctor maximus, justo neque porttitor ante, at
                varius sapien augue in tortor. Duis tempor convallis sem sed
                bibendum
              </p>
              <div className="media">
                <img
                  src="./assets/images/icons/big-avatar.png"
                  alt="XYZ User"
                  className="img-fluid"
                />
                <div className="media-body">
                  <h6>XYZ User</h6>
                  <span>CEO @XYZ Company</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-12 col-md-6">
            <div className="review-box">
              <h5>I absolutely love it</h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                iaculis, sem id auctor maximus, justo neque porttitor ante, at
                varius sapien augue in tortor. Duis tempor convallis sem sed
                bibendum
              </p>
              <div className="media">
                <img
                  src="./assets/images/icons/big-avatar.png"
                  alt="XYZ User"
                  className="img-fluid"
                />
                <div className="media-body">
                  <h6>XYZ User</h6>
                  <span>CEO @XYZ Company</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
