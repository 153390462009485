import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import axios from "axios";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";

const duration = [
  { value: "week", name: "Week" },
  { value: "month", name: "Month" },
  { value: "year", name: "Year" },
];
const paymentMethods = [
  { value: "stripe", name: "Pay by Card", icon: "card" },
  { value: "cryptomus", name: "Pay by Crypto", icon: "bitco" },
];
const Checkout = () => {
  const [selected, setSelected] = useState("starter");
  const [planType, setPlanType] = useState("");
  const [coupon, setCoupon] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("stripe");
  const [price, setPrice] = useState(0);
  const [showCoupon, setShowCoupon] = useState(false);
  const [loading, setLoading] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [allPlans, setAllPlans] = useState([]);
  const location = useLocation();

  const { selectedPlan } = location.state || {};
  console.log(selectedPlan);
  const getSinglePlans = async () => {
    await axios
      .get(
        `https://api.instarise.xyz/api/v1/admin/plan-pricing/${selectedPlan}`
      )
      .then((res) => {
        setPrice(res.data.data.price);
        setPlanType(res.data.data._id);
        setSelected(res.data.data.planType);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (selectedPlan) getSinglePlans();
  }, [selectedPlan]);

  const getFinalPricing = async () => {
    const data = {
      coupon,
      price,
    };
    await axios
      .post(`https://api.instarise.xyz/api/v1/order/apply-coupon`, data)
      .then((res) => {
        console.log("res", res);
        setPrice(res.data.finalPrice);
        setDiscountPercentage(res.data.discountPercentage);
      })
      .catch((err) => {
        console.log(err);
        setDiscountPercentage(err.response.data.message);
      });
  };

  const getPlans = async () => {
    await axios
      .get(`https://api.instarise.xyz/api/v1/admin/plan-pricing`)
      .then((res) => {
        setAllPlans(res.data.data);
        if (!selectedPlan) {
          setPrice(
            res.data.data.find((item) => item.planType === selected)[0]
              ?.price || res.data.data[0].price
          );
          setPlanType(
            res.data.data.find((item) => item.planType === selected)[0]?._id ||
              res.data.data[0]._id
          );
          setSelected(
            res.data.data.find((item) => item.planType === selected)[0]
              ?.planType || res.data.data[0].planType
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getPlans();
  }, []);
  console.log(selected, planType, price);
  const createOrder = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      price,
      plan: planType,
      name: e.target.name.value,
      phone: e.target?.phone?.value || 1234567890,
      email: e.target.email.value,
      paymentMethod,
    };
    await axios
      .post(`https://api.instarise.xyz/api/v1/order/create`, data)
      .then((res) => {
        console.log("res", res);
        window.location.href = res.data.url;
        setLoading(false);
        toast.success("Order Created Successfully");
        setPrice(0);
        setDiscountPercentage(0);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };
  return (
    <>
      {/* header section start */}
      <Header />
      {/* header section end */}
      {/* terms text */}
      <section className="py-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="checkout-left">
                <h3>Purchase Plan</h3>
                <div className="check-selcet-plan-box">
                  <div className="d-flex">
                    <p>Select Plan</p>
                    {/* <div className="form-check form-switch">
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckChecked"
                      >
                        Yearly
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        defaultChecked=""
                      />
                    </div> */}
                  </div>
                  {/* items */}
                  {/* <div className="d-flex  justify-content-between">
                    <div className="pricing-head-tab position-relative mb-2">
                      <ul
                        className="nav nav-pills mb-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className={`nav-link ${
                              accountType === "oneaccount" ? "active" : ""
                            } `}
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-home"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                            onClick={() => setAccountType("oneaccount")}
                          >
                            1 Account
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className={`nav-link ${
                              accountType === "twoaccount" ? "active" : ""
                            } `}
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-profile"
                            type="button"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                            onClick={() => setAccountType("twoaccount")}
                          >
                            2 Accounts
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className={`nav-link ${
                              accountType === "fiveaccount" ? "active" : ""
                            } `}
                            id="pills-contact-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-contact"
                            type="button"
                            role="tab"
                            aria-controls="pills-contact"
                            aria-selected="false"
                            onClick={() => setAccountType("fiveaccount")}
                          >
                            3 Accounts
                          </button>
                        </li>
                      </ul>
                      <div className="discount-tag position-absolute">
                        <img
                          src="./assets/images/icons/discount-tag.svg"
                          alt="discount-tag"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div> */}
                  {allPlans.map((e) => (
                    <div
                      className={`plan-box ${
                        selected === e.planType ? "active" : ""
                      }  `}
                      onClick={() => {
                        setPrice(e.price);
                        setSelected(e.planType);
                      }}
                    >
                      <div className="media">
                        <div className="media-body">
                          <h5 className="text-capitalize">{e.planType}</h5>
                          <h6>
                            Billed per user / per{" "}
                            {e.planType === "starter"
                              ? "week"
                              : e.planType === "plus"
                              ? "month"
                              : "year"}
                          </h6>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            checked={selected === e.planType}
                            onChange={() => {
                              setPlanType(e._id);
                              setSelected(e.planType);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            ${e.price}
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="have-cupon-box">
                  <p>Have a coupon code ?</p>
                  <button
                    className="btn btn-outline-secondary text-white"
                    onClick={() => setShowCoupon(!showCoupon)}
                  >
                    Add Coupon
                  </button>
                </div>
                {showCoupon && (
                  <div className="add-cupon-filed " id="cuponForm">
                    <div className="d-flex">
                      <label htmlFor="">Coupon Code</label>
                      {discountPercentage === "Invalid coupon code" ? (
                        <span className="text-danger">
                          {discountPercentage}
                        </span>
                      ) : discountPercentage !== "" ? (
                        <span>Congrats! {discountPercentage}% OFF</span>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <div className="form-group position-relative">
                      <input
                        type="text"
                        placeholder="Enter Code here"
                        className="form-control input mt-3 "
                        onChange={(e) => setCoupon(e.target.value)}
                        value={coupon}
                      />
                      <button
                        type="button"
                        style={{ color: "#695dff" }}
                        className="btn btn-submit "
                        onClick={() => getFinalPricing()}
                      >
                        APPLY
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="payment-card-box">
                <h4>Order Details</h4>
                <h6>
                  All the order details would be shared via your email address,
                  please add your preferred payment method
                </h6>
                <form onSubmit={createOrder}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <label htmlFor="" className="label">
                          Name
                        </label>
                        <input
                          type="text"
                          placeholder="Your Name"
                          className="form-control input"
                          name="name"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <label htmlFor="" className="label">
                          Phone (Optional)
                        </label>
                        <input
                          type="text"
                          placeholder="+123 - 456 - 789"
                          className="form-control input"
                          name="phone"
                          onChange={(e) => setPhone(e.target.value)}
                          value={phone}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mt-4">
                        <label htmlFor="" className="label">
                          Email Address
                        </label>
                        <input
                          type="text"
                          placeholder="username@example.com"
                          className="form-control input"
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          required
                        />
                      </div>
                    </div>
                    {paymentMethods.map((e) => (
                      <div
                        className="col-lg-6"
                        key={e.value}
                        onClick={() => setPaymentMethod(e.value)}
                      >
                        <div className="form-group mt-4">
                          <label htmlFor="" className="label">
                            Payment Method
                          </label>
                        </div>
                        <div
                          className={`pay-by ${
                            e.value === paymentMethod ? "active" : ""
                          }`}
                        >
                          <p>
                            {" "}
                            <img
                              src={`./assets/images/${e.icon}.svg`}
                              alt="a"
                              className="img-fluid me-2"
                            />{" "}
                            {e.name}
                          </p>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault5"
                              id="flexRadioDefault134"
                              onChange={() => setPaymentMethod(e.value)}
                              checked={e.value === paymentMethod}
                            />
                          </div>
                        </div>
                      </div>
                    ))}

                    {/* <div className="col-lg-12">
                      <div className="form-group mt-4 position-relative">
                        <label htmlFor="" className="label">
                          Card Number
                        </label>
                        <input
                          type="text"
                          placeholder="1234 5678 9101 2345"
                          className="form-control input"
                        />
                        <img
                          src="./assets/images/icons/mastercard.svg"
                          alt="a"
                          className="img-fluid abs"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4 position-relative">
                        <label htmlFor="" className="label">
                          Expiration Date
                        </label>
                        <input
                          type="text"
                          placeholder="00/00"
                          className="form-control input"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4 position-relative">
                        <label htmlFor="" className="label">
                          CVV
                        </label>
                        <input
                          type="text"
                          placeholder="***"
                          className="form-control input"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mt-4 position-relative">
                        <label htmlFor="" className="label">
                          Cardholder Name
                        </label>
                        <input
                          type="text"
                          placeholder="***"
                          className="form-control input"
                        />
                      </div>
                    </div> */}
                    <div className="col-lg-12">
                      <div className="form-submit mt-4">
                        <button
                          disabled={price === 0 || loading}
                          type="submit"
                          className="btn get-bttn border-0 d-flex w-100 py-3"
                        >
                          {loading ? (
                            <Spinner animation="grow" variant="primary" />
                          ) : (
                            <>
                              Pay ${price.toFixed(2) ?? 0.0}
                              <i className="fas fa-arrow-right ms-2" />
                            </>
                          )}
                        </button>
                      </div>
                      <div className="text-center mt-4">
                        <img
                          src="./assets/images/secured.svg"
                          alt="secured"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* terms text */}
      {/* footer section start */}
      <footer className="footer_section ">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="brdr-top" />
            </div>
            <div className="col-lg-6 col-12">
              <div className="ftr-copy">
                <p className="para">
                  © 2024 InstaRISE.&nbsp;All rights reserved
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ftr-menus">
                <ul className="justify-content-start mt-3">
                  <li>
                    <a href="#">Terms</a>
                  </li>
                  <li>
                    <a href="/order/123">Privacy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* footer section end */}
    </>
  );
};

export default Checkout;
