import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const NotFound = () => {
  return (
    <>
      {/* header section start */}
      <Header />
      {/* header section end */}
      {/* 404 error */}
      <section className="py-100">
        <div className="container">
          {/* common heading start */}
          <div className="row">
            <div className="col-lg-12">
              <div className="error-main-txt">
                <img
                  src="../assets/images/icons/404.svg"
                  alt="a"
                  className="img-fluid"
                />
                <h1>
                  Oops! We encountered <br />
                  an error
                </h1>
                <p>
                  This page does not exist, please contact support <br />
                  if you have any issues.
                </p>
                <a href="/" className="get-bttn">
                  <i className="fas fa-arrow-left me-2 ms-0" /> Back to Homepage
                </a>
              </div>
            </div>
          </div>
          {/* common heading end */}
        </div>
      </section>
      {/* 404 error */}
      {/* footer section start */}
      <Footer />
      {/* footer section end */}
    </>
  );
};

export default NotFound;
