import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

import PricingSec from "../components/PricingSec";
const Pricing = () => {
  return (
    <>
      {/* header section start */}
      <Header />
      {/* header section end */}
      {/* pricing section start */}
      <section className="py-100">
        <PricingSec />
      </section>
      {/* pricing section end */}
      {/* footer section start */}
      <Footer />
      {/* footer section end */}
    </>
  );
};

export default Pricing;
