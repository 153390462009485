import React from "react";

const Features = () => {
  return (
    <section className="py-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="common-heading">
              <h2>Powerpacked with Features</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
                Praesent mollis erat sed venenatis tempus.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 mb-4">
            <div className="step-box step-custom-padding">
              <div className="row">
                <div className="col-lg-6">
                  <div className="icon">
                    <img
                      src="./assets/images/icons/powerd-icon-01.svg"
                      alt="a"
                      className="img-fluid"
                    />
                  </div>
                  <h4>Feature Number 01</h4>
                  <p className="mb-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam iaculis, sem id auctor maximus, justo neque porttitor
                    ante, at varius sapien augue in tortor. Duis tempor
                    convallis sem sed bibendum
                  </p>
                  <a
                    href="#"
                    className="white-bttn"
                    style={{ textDecoration: "none" }}
                  >
                    Get Started <i className="fas fa-arrow-right ms-2" />
                  </a>
                </div>
                <div className="col-lg-6">
                  <div className="text-center">
                    <img
                      src="./assets/images/placeholder.svg"
                      alt="a"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12 col-md-6">
            <div className="step-box custom-padding-2 pb-0">
              <div className="icon">
                <img
                  src="./assets/images/icons/powerd-icon-02.svg"
                  alt="a"
                  className="img-fluid"
                />
              </div>
              <h4>Feature Number 02</h4>
              <p className="mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                iaculis, sem id auctor maximus, justo neque porttitor ante, at
                varius sapien augue in tortor. Duis tempor convallis sem sed
                bibendum
              </p>
              <img
                src="./assets/images/placeholder-2.svg"
                alt="a"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-lg-6 col-12 col-md-6">
            <div className="step-box custom-padding-2 pb-0">
              <div className="icon">
                <img
                  src="./assets/images/icons/powerd-icon-03.svg"
                  alt="a"
                  className="img-fluid"
                />
              </div>
              <h4>Feature Number 03</h4>
              <p className="mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                iaculis, sem id auctor maximus, justo neque porttitor ante, at
                varius sapien augue in tortor. Duis tempor convallis sem sed
                bibendum
              </p>
              <img
                src="./assets/images/placeholder-2.svg"
                alt="a"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
