import React from "react";
const Header = () => {
  return (
    <header className="header_section">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light custom-header">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              <img
                src="../assets/images/logo.svg"
                alt="Logo"
                className="img-fluid"
              />
            </a>
            {/* <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
          aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
          <i class="fa-solid fa-bars-staggered"></i>
        </button> */}

            <a href="/checkout" className="d-lg-none get-bttn">
              Get Started <i className="fas fa-arrow-right" />
            </a>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav m-auto">
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    How To
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/pricing">
                    Pricing
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Reviews
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    FAQs
                  </a>
                </li>
              </ul>
              <form className="d-flex">
                <a
                  href="/checkout"
                  style={{ textDecoration: "none", underline: "none" }}
                >
                  Get Started <i className="fas fa-arrow-right" />
                </a>
              </form>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
