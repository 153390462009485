import "./css/style.css";
import "./css/responsive.css";
import Landing from "./pages/Landing";
import { Route, Routes } from "react-router-dom";
import Terms from "./pages/Terms";
import Pricing from "./pages/Pricing";
import Checkout from "./pages/Checkout";
import NotFound from "./pages/NotFound";
import OrderInfo from "./pages/OrderInfo";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/order/:id" element={<OrderInfo />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
      <ToastContainer
        position="top-center"
        hideProgressBar
        draggable={false}
        theme="colored"
      />
    </>
  );
}

export default App;
