import React from "react";

const StarterPlan = ({ data, handleGetStarted }) => {
  console.log(data);
  return (
    <div className="col-lg-4 col-md-6">
      {" "}
      <div className="pricing-box-wrap">
        <h3>Starter</h3>
        <h6>For users just starting out</h6>
        <div className="d-flex">
          <h4>${data?.price ?? 0}</h4>
          <span>
            per user/week <br />
            billed weekly
          </span>
        </div>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleGetStarted(data);
          }}
          style={{ textDecoration: "none" }}
        >
          Get Started <i className="fas fa-arrow-right" />
        </a>
        {/* <h6>What’s included in Starter :</h6>
        <div className="media">
          <i className="fas fa-check" />
          <div className="media-body">
            <h5>Everything Automated</h5>
            <p>Auto Story Likes, Follow/Unfollow</p>
          </div>
        </div>
        <div className="media">
          <i className="fas fa-check" />
          <div className="media-body">
            <h5>Followers per month</h5>
            <p>500 to 1,000 Followers</p>
          </div>
        </div>
        <div className="media">
          <i className="fas fa-check" />
          <div className="media-body">
            <h5>Exclusives</h5>
            <p>Auto Story Likes, Follow/Unfollow</p>
          </div>
        </div>
        <div className="media closed">
          <i className="fas fa-close" />
          <div className="media-body">
            <h5>Expert Tutorials and Prompts</h5>
            <p>Full Access to our expert videos</p>
          </div>
        </div>
        <div className="media closed">
          <i className="fas fa-close" />
          <div className="media-body">
            <h5>Dedicated Instagram Expert</h5>
            <p>Personal Instagram Expert for you</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export const PlusPlan = ({ data, handleGetStarted }) => {
  return (
    <div className="col-lg-4 col-md-6">
      {" "}
      <div className="pricing-box-wrap active">
        <div className="flex">
          <div>
            <h3>Plus</h3>
            <h6>Our Best Seller</h6>
          </div>
          {/* <div className="form-check form-switch">
                            <label
                              className="form-check-label"
                              htmlFor="flexSwitchCheckChecked"
                            >
                              Yearly
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckChecked"
                              defaultChecked=""
                            />
                          </div> */}
        </div>
        <div className="d-flex">
          <h4>${data?.price ?? 0}</h4>
          <span>
            per user/month <br />
            billed monthly
          </span>
        </div>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleGetStarted(data);
          }}
          style={{ textDecoration: "none" }}
        >
          Get Started <i className="fas fa-arrow-right" />
        </a>
        {/* <h6>What’s included in Starter :</h6>
        <div className="media">
          <i className="fas fa-check" />
          <div className="media-body">
            <h5>Everything Automated</h5>
            <p>Auto Story Likes, Follow/Unfollow</p>
          </div>
        </div>
        <div className="media">
          <i className="fas fa-check" />
          <div className="media-body">
            <h5>Followers per month</h5>
            <p>500 to 1,000 Followers</p>
          </div>
        </div>
        <div className="media">
          <i className="fas fa-check" />
          <div className="media-body">
            <h5>Exclusives</h5>
            <p>Auto Story Likes, Follow/Unfollow</p>
          </div>
        </div>
        <div className="media ">
          <i className="fas fa-check" />
          <div className="media-body">
            <h5>Expert Tutorials and Prompts</h5>
            <p>Full Access to our expert videos</p>
          </div>
        </div>
        <div className="media closed">
          <i className="fas fa-close" />
          <div className="media-body">
            <h5>Dedicated Instagram Expert</h5>
            <p>Personal Instagram Expert for you</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export const PremiumPlan = ({ data, handleGetStarted }) => {
  return (
    <div className="col-lg-4 col-md-6">
      {" "}
      <div className="pricing-box-wrap">
        <h3>Premium</h3>
        <h6>For users who want extra</h6>
        <div className="d-flex">
          <h4>${data?.price ?? 0}</h4>
          <span>
            per user/year <br />
            billed yearly
          </span>
        </div>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleGetStarted(data);
          }}
          style={{ textDecoration: "none" }}
        >
          Get Started <i className="fas fa-arrow-right" />
        </a>
        {/* <h6>What’s included in Starter :</h6>
        <div className="media">
          <i className="fas fa-check" />
          <div className="media-body">
            <h5>Everything Automated</h5>
            <p>Auto Story Likes, Follow/Unfollow</p>
          </div>
        </div>
        <div className="media">
          <i className="fas fa-check" />
          <div className="media-body">
            <h5>Followers per month</h5>
            <p>500 to 1,000 Followers</p>
          </div>
        </div>
        <div className="media">
          <i className="fas fa-check" />
          <div className="media-body">
            <h5>Exclusives</h5>
            <p>Auto Story Likes, Follow/Unfollow</p>
          </div>
        </div>
        <div className="media">
          <i className="fas fa-check" />
          <div className="media-body">
            <h5>Expert Tutorials and Prompts</h5>
            <p>Full Access to our expert videos</p>
          </div>
        </div>
        <div className="media">
          <i className="fas fa-check" />
          <div className="media-body">
            <h5>Dedicated Instagram Expert</h5>
            <p>Personal Instagram Expert for you</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default StarterPlan;
