import React from "react";
import Hero from "../components/Hero";
import "../css/style.css";
import "../css/responsive.css";
import Results from "../components/Results";
import SimpleAs from "../components/SimpleAs";
import Features from "../components/Features";
import PricingSec from "../components/PricingSec";
import Review from "../components/Review";
import FaqLanding from "../components/FaqLanding";
import Footer from "../components/Footer";
const Landing = () => {
  return (
    <div className="main-bg">
      <Hero />
      <Results />
      <SimpleAs />
      <Features />
      <PricingSec />
      <Review />
      <FaqLanding />
      <section className="get-start-section mb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="get-start-bg">
                <div className="get-start-txt">
                  <img
                    src="./assets/images/get-tag-label.svg"
                    alt="a"
                    className="img-fluid"
                  />
                  <h2>
                    So what are you <br />
                    waiting for?
                  </h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
                    <br />
                    Praesent mollis erat sed venenatis tempus.
                  </p>
                  <a
                    href="#"
                    className="white-bttn"
                    style={{ textDecoration: "none" }}
                  >
                    Get Started <i className="fas fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Landing;
