import React from "react";

const Footer = () => {
  return (
    <footer className="footer_section ">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="ftr-logo">
              <a href="/">
                <img
                  src="../assets/images/logo.svg"
                  alt="Logo"
                  className="img-fluid"
                />
              </a>
              <p>
                Lorem ipsum dolor sit amet, <br />
                consectetur adipiscing elit. Praesent <br /> mollis erat sed
                venenatis tempus.
              </p>
            </div>
          </div>
          <div className="col-lg-2 col-6 ">
            <div className="ftr-links">
              <h6 style={{ marginLeft: "30px" }}>Explore</h6>
              <ul>
                <li>
                  <a href="#" style={{ textDecoration: "none" }}>
                    How To
                  </a>
                </li>
                <li>
                  <a href="#" style={{ textDecoration: "none" }}>
                    Pricing
                  </a>
                </li>
                <li>
                  <a href="#" style={{ textDecoration: "none" }}>
                    Reviews
                  </a>
                </li>
                <li>
                  <a href="#" style={{ textDecoration: "none" }}>
                    FAQs
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-6 ">
            <div className="ftr-links">
              <h6 style={{ marginLeft: "30px" }}>Social Media</h6>
              <ul>
                <li>
                  <a href="#" style={{ textDecoration: "none" }}>
                    Instagram
                  </a>
                </li>
                <li>
                  <a href="#" style={{ textDecoration: "none" }}>
                    Twitter
                  </a>
                </li>
                <li>
                  <a href="#" style={{ textDecoration: "none" }}>
                    Telegram
                  </a>
                </li>
                <li>
                  <a href="#" style={{ textDecoration: "none" }}>
                    Discord
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="brdr-top" />
          </div>
          <div className="col-lg-6 col-12">
            <div className="ftr-copy">
              <p className="para">© 2024 InstaRISE.&nbsp;All rights reserved</p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ftr-menus">
              <ul className="justify-content-start mt-3">
                <li>
                  <a href="/terms" style={{ textDecoration: "none" }}>
                    Terms
                  </a>
                </li>
                <li>
                  <a href="#" style={{ textDecoration: "none" }}>
                    Privacy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
