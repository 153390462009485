import React from "react";

const SimpleAs = () => {
  return (
    <section className="py-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="common-heading">
              <h2>
                Simple as 1 <i className="fas fa-arrow-right" /> 2{" "}
                <i className="fas fa-arrow-right" /> 3
              </h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
                Praesent mollis erat sed venenatis tempus.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-12 col-md-6">
            <div className="step-box">
              <div className="icon">
                <img
                  src="./assets/images/icons/simple-icon-01.svg"
                  alt="a"
                  className="img-fluid"
                />
              </div>
              <h4>Select a Plan</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                iaculis, sem id auctor maximus, justo neque porttitor ante, at
                varius sapien augue in tortor. Duis tempor convallis sem sed
                bibendum
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-12 col-md-6">
            <div className="step-box">
              <div className="icon">
                <img
                  src="./assets/images/icons/simple-icon-02.svg"
                  alt="a"
                  className="img-fluid"
                />
              </div>
              <h4>Email Delivery</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                iaculis, sem id auctor maximus, justo neque porttitor ante, at
                varius sapien augue in tortor. Duis tempor convallis sem sed
                bibendum
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-12 col-md-6">
            <div className="step-box">
              <div className="icon">
                <img
                  src="./assets/images/icons/simple-icon-03.svg"
                  alt="a"
                  className="img-fluid"
                />
              </div>
              <h4>Request Activated</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                iaculis, sem id auctor maximus, justo neque porttitor ante, at
                varius sapien augue in tortor. Duis tempor convallis sem sed
                bibendum
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SimpleAs;
